import React, { useEffect, useMemo, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { BiMenu } from "react-icons/bi";
import { CgClose, CgMenu } from "react-icons/cg";
import { Link, useLocation } from "react-router-dom";
import WebsiteData from "../Data/WebsiteData";
import { loadSlim } from "@tsparticles/slim";
import Particles, { initParticlesEngine } from "@tsparticles/react";
// Define an array of link data
const links = [
    { to: "/home", text: "Home", newWindow: false },
    { to: "/projects", text: "Projects", newWindow: false },
    { to: WebsiteData.pfData["resumeLink"], text: "Resume", newWindow: true },
    { to: "/home#contact", text: "Contact", newWindow: false }
];

export default function CustomHeader() {

    const [init, setInit] = useState(false);


    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const [menuOpen, setMenuOpen] = useState(false);
    const [activeLink, setActiveLink] = useState("");
    const location = useLocation();

    // useEffect(() => {
    //     particlesJS('particles-js', particlesConfig);
    // }, []);

    useEffect(() => {

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setActiveLink(location.pathname + location.hash);

    }, [location]);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };
    useEffect(() => {
        initParticlesEngine(async (engine) => {
            // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
            // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
            // starting from v2 you can add only the features you need reducing the bundle size
            await loadSlim(engine);
            //await loadFull(engine);
            // await loadSlim(engine);
            //await loadBasic(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    const particlesLoaded = (container) => {
        console.log(container);
    };

    const options = useMemo(
        () => ({
            background: {
                color: {
                    value: "black", // dark blue background
                },
            },

            fpsLimit: 120,
            interactivity: {
                events: {
                    onClick: {
                        enable: true,
                        mode: "push",
                    },
                    onHover: {
                        enable: true,
                        mode: "repulse",
                    },
                },
                modes: {
                    push: {
                        quantity: 20,

                    },
                    repulse: {
                        distance: 1,
                        duration: 0.4,
                    },
                },
            },
            particles: {
                color: {
                    value: "#ffffff", // white stars
                },
                links: {
                    enable: false, // no links between stars
                },
                move: {
                    // direction: "bottom",
                    enable: true,
                    outModes: {
                        default: "out",
                    },
                    random: true,
                    speed: 1.3,
                    straight: true,
                },
                number: {
                    density: {
                        enable: true,
                    },
                    value: 100, // more stars
                },
                opacity: {
                    value: 1,
                },
                shape: {
                    type: "square",
                },
                size: {
                    value: { min: 0.1, max: 1.2 }, // smaller stars
                },
                twinkle: {
                    enable: true,
                    frequency: 0.02, // slower twinkling frequency
                    opacity: {
                        animate: {
                            enable: true,
                            speed: 0.5, // slower animation speed
                            sync: false,
                        },
                        random: {
                            enable: true,
                            minimumValue: 0.1, // lower minimum opacity for a more subtle effect
                            maximumValue: 0.8, // higher maximum opacity for a more dramatic effect
                        },
                    },
                },
            },
            detectRetina: true,
        }),
        [],
    );

    if (init) {
        return (
            <div>
                <div>
                    <Particles

                        id="tsparticles"
                        particlesLoaded={particlesLoaded}
                        options={options}
                        style={{ height: `${isMobile ? "80px" : "100px"}`, }}
                    />
                </div>
                <div>

                    {/* <div id="particles-js" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} /> */}
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        style={{
                            position: "fixed",
                            height: `${isMobile ? "80px" : "100px"}`,
                            alignItems: "center",
                            width: "100%",
                            color: "white",
                            display: "flex",
                            justifyContent: "space-between",
                            padding: `0px ${isMobile ? "30px" : "100px"}`,
                            zIndex: 1000, // Ensure the header has a high z-index
                            // backgroundColor: "rgba(0, 0, 0, 0.9)", // Optional: Add background color to the header
                        }}
                    >

                        <Link to="/home" replace style={{
                            fontFamily: "RantaiSignature",
                            fontSize: "40px",
                            marginRight: "10px",
                            color: "white",
                            textDecoration: "none"
                        }}>yash . d</Link>

                        {isMobile ? (
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ marginLeft: "20px", cursor: "pointer" }} onClick={toggleMenu}>
                                    <CgMenu style={{ fontSize: "30px", color: "white" }} />
                                </div>
                                <AnimatePresence>
                                    {menuOpen && (
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                position: "absolute",
                                                top: "0px",
                                                left: "0",
                                                right: "0",
                                                backgroundColor: "black",
                                                zIndex: 9999,
                                                height: "100vh",
                                                width: "100vw",
                                                padding: "30px",
                                            }}
                                        >
                                            <CgClose style={{ color: "white", border: "none", margin: "20px 0px", height: isMobile ? "40px" : "60px", width: isMobile ? "40px" : "60px" }} onClick={toggleMenu} />
                                            {links.map((link, index) => (
                                                <motion.div
                                                    key={index}
                                                    initial={{ opacity: 0, y: -20 }}
                                                    animate={{ opacity: 1, y: 0 }}
                                                    exit={{ opacity: 0, y: -20 }}
                                                    transition={{ duration: (index + 0.4) * 0.3 }}
                                                >
                                                    {link.newWindow ? (
                                                        <div style={{ marginBottom: "10px" }}>
                                                            <a href={link.to} target="_blank" rel="noopener noreferrer" style={{
                                                                color: activeLink === link.to ? "white" : "grey",
                                                                marginRight: "10px",
                                                                marginBottom: "15px",
                                                                textDecoration: "none",
                                                                fontSize: isMobile ? "40px" : "70px",
                                                                textTransform: isMobile ? "none" : "uppercase"
                                                            }}>{link.text}</a>
                                                        </div>
                                                    ) : (
                                                        <div style={{ marginBottom: "10px" }}>
                                                            <Link to={link.to} replace style={{
                                                                color: activeLink === link.to ? "white" : "grey",
                                                                marginRight: "10px",
                                                                marginBottom: "20px",
                                                                textDecoration: "none",
                                                                fontSize: isMobile ? "40px" : "70px",
                                                                textTransform: isMobile ? "none" : "uppercase"
                                                            }}>{link.text}</Link>
                                                        </div>

                                                    )}
                                                </motion.div>
                                            ))}
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </div>
                        ) : (
                            <div style={{ display: "flex", alignItems: "center", }}>
                                {links.map((link, index) => (
                                    <React.Fragment key={index}>
                                        {link.newWindow ? (
                                            <a href={link.to} target="_blank" rel="noopener noreferrer" style={{
                                                marginRight: "20px",
                                                fontSize: "12px",
                                                border: activeLink === link.to ? "0.2px solid white" : "none",
                                                borderRadius: activeLink === link.to ? "20px" : "0px",
                                                padding: "5px 15px",
                                                color: activeLink === link.to ? "white" : "grey",
                                                textDecoration: "none",
                                                textTransform: isMobile ? "none" : "uppercase"
                                            }}>{link.text}</a>
                                        ) : (
                                            <Link to={link.to} replace style={{
                                                marginRight: "15px",
                                                fontSize: "12px",
                                                border: activeLink === link.to ? "0.2px solid white" : "none",
                                                borderRadius: activeLink === link.to ? "20px" : "0px",
                                                padding: "5px 10px",
                                                color: activeLink === link.to ? "white" : "grey",
                                                textDecoration: "none",
                                                textTransform: isMobile ? "none" : "uppercase"
                                            }}>{link.text}</Link>
                                        )}
                                    </React.Fragment>
                                ))}

                            </div>
                        )}
                    </motion.div>
                </div>
            </div>
        );
    }



}
