
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
export default function Heading(props) {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Change the threshold according to your design
        };

        handleResize(); // Initial check
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
        >
            <div style={{ fontSize: isMobile ? "25px" : "50px" }}>
                {props.name}
            </div>
            {/* <div style={{ marginTop: "3px", position: "absolute", height: "5px", width: "50px", backgroundColor: "#414141" }}></div> */}
        </motion.div>);
}