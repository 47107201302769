import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import Heading from "./Heading";
import WebsiteData from "../Data/WebsiteData";

export function AboutSection() {
    const [isMobile, setIsMobile] = useState(false);
    const [text, setText] = useState("");

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Change the threshold according to your design
        };

        handleResize(); // Initial check
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        // const sentence = "I'm dedicated to crafting visually captivating software interfaces that resonate deeply with users, leaving a lasting impression.";

        const sentence = WebsiteData.pfData["about"];
        let index = 0;
        const intervalId = setInterval(() => {
            setText(sentence.substring(0, index + 1));
            index++;
            if (index === sentence.length) clearInterval(intervalId);
        }, 50);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <motion.div
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            style={{
                height: "100vh",
                padding: isMobile ? "40px 0px" : "100px 0px",
                display: "flex",
                flexDirection: "column",
                textAlign: "start"
            }}
        >
            <div style={{ height: "100%", alignItems: isMobile ? "center" : "center", justifyContent: "start", display: "flex" }}>
                <div style={{ fontSize: isMobile ? "30px" : "50px", fontWeight: isMobile ? "normal" : "normal", margin: `0px ${isMobile ? "20px" : "100px"}`, lineHeight: isMobile ? "1.5" : "1.6" }}>
                    {text}
                </div>
            </div>
        </motion.div>
    );
}
