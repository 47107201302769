
import { useState } from 'react';
import CustomHeader from "../MainComponent/CustomHeader";
import { motion } from "framer-motion";
// Sample JSON data for blogs
const blogsData = [
    {
        id: 1,
        title: "Blog 1",
        content: "This is the content of Blog 1. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        topic: "topic1",
        imageUrl: "https://example.com/image1.jpg" // Add imageUrl
    },
    {
        id: 2,
        title: "Blog 2",
        content: "This is the content of Blog 2. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        topic: "topic2",
        imageUrl: "https://example.com/image2.jpg" // Add imageUrl
    },
    // Add more blog objects as needed
];

export default function BlogsScreen() {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedTopic, setSelectedTopic] = useState('all');

    // Filter blogs based on search term and selected topic
    const filteredBlogs = blogsData.filter(blog => {
        // Filter by search term
        const searchTermMatch = blog.title.toLowerCase().includes(searchTerm.toLowerCase());
        // Filter by selected topic
        const topicMatch = selectedTopic === 'all' || blog.topic === selectedTopic;
        return searchTermMatch && topicMatch;
    });

    // Function to handle search input change
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    // Function to handle topic filter change
    const handleTopicChange = (event) => {
        setSelectedTopic(event.target.value);
    };

    return (
        <div style={{ height: "100vh", width: "100vw", backgroundColor: "black", color: "white", overflow: "hidden", }}>
            <CustomHeader />
            <div style={{ fontSize: "40px", margin: "30px 20px", marginBottom: "20px", }}>Blogs</div>
            {/* Search bar */}
            <input type="text" placeholder="Search blogs..." value={searchTerm} onChange={handleSearchChange} style={{ marginBottom: '20px', marginLeft: '20px', padding: '5px', fontSize: '16px' }} />
            {/* Topic filter */}
            <select value={selectedTopic} onChange={handleTopicChange} style={{ marginBottom: '20px', marginLeft: '20px', padding: '5px', fontSize: '16px' }}>
                <option value="all">All Topics</option>
                <option value="topic1">Topic 1</option>
                <option value="topic2">Topic 2</option>
                {/* Add more options as needed */}
            </select>
            {/* Blog cards grid */}
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', gap: '20px', padding: '0 20px', }}>
                {filteredBlogs.map(blog => (
                    <BlogCard key={blog.id} blog={blog} />
                ))}
            </div>
        </div>
    );
}

export function BlogCard({ blog }) {
    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} whileHover={{ scale: 1.05 }} style={{ backgroundColor: "#1E1E1E", padding: '20px', borderRadius: '8px' }}>
            {/* Display blog image */}
            <motion.img src={blog.imageUrl} alt="Blog" style={{ width: '100%', marginBottom: '15px', borderRadius: '4px' }} />
            <h2 style={{ fontSize: "25px", marginBottom: "15px" }}>{blog.title}</h2>
            <p style={{ fontSize: "15px", lineHeight: "1.6", textAlign: "justify" }}>{blog.content}</p>
            <div style={{ textAlign: "start", marginTop: "15px" }}>
                <div style={{ fontSize: "15px" }}>show more</div>
            </div>
        </motion.div>
    );
}