import { motion } from "framer-motion";
import CustomHeader from "../MainComponent/CustomHeader";
import Footer from "../MainComponent/Footer";
import { useEffect, useState } from "react";
import ThemeConfigg from "../ThemeConfig";
import CoderImage from "./CoderImage";
import { RiTextDirectionL } from "react-icons/ri";
import { LiaLinkedin } from "react-icons/lia";
import LandingMainSection from "./LandingMainSection";
import { AboutSection } from "./AboutSection";
import Heading from "./Heading";
import ExperienceSection from "./ExperienceSection";
import SkillsSection from "./SkillsSection";
import { ContactSection } from "./ContactSection";
import { Link, ScrollRestoration } from "react-router-dom";

import CustomFooter from "../MainComponent/Footer";
import WebsiteData from "../Data/WebsiteData";
import ProjectSection from "./ProjectSection";



export default function HomeScreen() {
    return (
        <div >
            <CustomHeader />
            <div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                style={{ backgroundColor: "transparent", color: "white", width: "100vw", overflow: "hidden", }}
            >


                <LandingMainSection />
                <AboutSection />
                <ProjectSection />
                <SkillsSection />
                <ExperienceSection />
                <ContactSection />
                <CustomFooter />


            </div>
        </div>
    );
}
