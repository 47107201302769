import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import ThemeConfigg from "../ThemeConfig";
import Heading from "./Heading";
import WebsiteData from "../Data/WebsiteData";

export default function ExperienceSection() {
    const [isMobile, setIsMobile] = useState(false);
    const experienceData = WebsiteData.pfData["experience"];

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Change the threshold according to your design
        };

        handleResize(); // Initial check
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div>
            <motion.div
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.6, duration: 0.5 }}
                style={{
                    padding: "40px 0px",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "start"
                }}
            >
                <div style={{ padding: `0px ${isMobile ? ThemeConfigg.padding.pageHorizontalMobilePadding : ThemeConfigg.padding.pageHorizontalPadding}`, marginBlock: "30px", display: "flex", justifyContent: "space-between" }}>
                    <Heading name="Experience" />
                </div>
                <div style={{ height: "30px" }}></div>
                {/* Map over experienceData and render ExperienceCard for each item */}
                {experienceData.map((experience, index) => (
                    <ExperienceCard key={index} lastElement={experienceData.length - 1 == index} name={experience.name} duration={experience.duration} position={experience.position} />
                ))}
            </motion.div>
        </div>
    );
}


export function ExperienceCard(props) {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Change the threshold according to your design
        };

        handleResize(); // Initial check
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    if (isMobile)
        return (<div style={{ marginBottom: "30px", display: "flex", alignItems: "start", justifyContent: "center" }}>
            <div style={{ borderBottom: props.lastElement ? "none" : "0.5px solid white", width: "100%", margin: `0px ${ThemeConfigg.padding.pageHorizontalMobilePadding}`, padding: "40px 0px", borderRadius: "0px" }}>
                {/* Experience details */}

                <div style={{ display: 'flex', justifyContent: "start", alignItems: "start" }}>
                    <div style={{ fontSize: "20px", marginRight: "50px", width: "100px", maxWidth: "100px" }}>{props.duration}</div>


                    <div style={{ marginRight: "20px", alignItems: "start", textAlign: "start" }}>

                        <div style={{ marginBottom: "10px", fontSize: "20px", fontWeight: "bold" }}>{props.name}</div>

                        <div style={{ fontSize: "15px", color: "grey" }}>{props.position}</div>
                    </div>



                </div>
                {/* <div style={{ marginTop: "20px", textAlign: "justify" }}>Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description</div> */}

                {/* Add more experience details as needed */}
            </div>
        </div >);
    return (<div style={{ marginBottom: "30px", display: "flex", alignItems: "start", justifyContent: "center" }}>
        <div style={{ borderBottom: props.lastElement ? "none" : "1px solid white", width: "100%", margin: "0px 100px", padding: "40px 0px", borderRadius: "0px" }}>
            {/* Experience details */}

            <div style={{ display: 'flex', justifyContent: "start", alignItems: "start" }}>
                <div style={{ fontSize: "30px", marginRight: "50px", width: "150px" }}>{props.duration}</div>
                <div style={{ display: 'flex', justifyContent: "start", alignItems: "center" }}>

                    <div style={{ marginRight: "20px", }}>
                        <div style={{ marginBottom: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ marginRight: "20px", marginBottom: "0px", fontSize: "30px", fontWeight: "bold" }}>{props.name}</div>
                            {/* <img style={{ marginRight: "10px", width: "80px", height: "25px" }} src="https://zeevector.com/wp-content/uploads/npci-logo-PNG.png" />
                            <img style={{ marginRight: "0px", width: "80px", height: "48px" }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Bharat_BillPay_logo.svg/1200px-Bharat_BillPay_logo.svg.png" /> */}
                        </div>
                        <div style={{ fontSize: "20px", color: "grey" }}>{props.duration}</div>
                    </div>

                </div>

            </div>
            {/* <div style={{ marginTop: "20px", textAlign: "justify" }}>Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description</div> */}

            {/* Add more experience details as needed */}
        </div>
    </div>);
}

export function ExperienceCard2() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Change the threshold according to your design
        };

        handleResize(); // Initial check
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    if (isMobile)
        return (<div style={{ marginBottom: "20px", display: "flex", alignItems: "start", justifyContent: "center" }}>
            <div style={{ width: "100%", margin: "0px 30px", padding: "20px 30px", backgroundColor: "#212121", borderRadius: "5px" }}>
                {/* Experience details */}
                <div style={{ marginBottom: "20px", display: "flex", justifyContent: "start", alignItems: "center" }}>
                    <img style={{ marginRight: "10px", width: "80px", height: "25px" }} src="https://zeevector.com/wp-content/uploads/npci-logo-PNG.png" />
                    <img style={{ marginRight: "0px", width: "80px", height: "48px" }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Bharat_BillPay_logo.svg/1200px-Bharat_BillPay_logo.svg.png" />
                </div>
                <div style={{ marginRight: "20px", marginBottom: "5px", fontSize: "25px", fontWeight: "bold" }}>1. NPCI Bharat Billpay Ltd.</div>
                <div style={{ marginBottom: "20px", }}>Application Developer</div>
                <div style={{ marginBottom: "10px", color: "grey" }}>July 2022 - present</div>
                <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "end" }}>
                    <div style={{ display: 'flex', justifyContent: "start", alignItems: "center" }}>


                    </div>

                </div>
                <div style={{ marginTop: "20px", textAlign: "justify" }}>Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description</div>

                {/* Add more experience details as needed */}
            </div>
        </div>);
    return (<div style={{ marginBottom: "30px", display: "flex", alignItems: "start", justifyContent: "center" }}>
        <div style={{ width: "100%", margin: "0px 100px", padding: "40px 30px", backgroundColor: "#212121", borderRadius: "5px" }}>
            {/* Experience details */}

            <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "end" }}>
                <div style={{ display: 'flex', justifyContent: "start", alignItems: "center" }}>

                    <div style={{ marginRight: "20px", }}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ marginRight: "20px", marginBottom: "0px", fontSize: "25px", fontWeight: "bold" }}>1. NPCI Bharat Billpay Ltd.</div>
                            <img style={{ marginRight: "10px", width: "80px", height: "25px" }} src="https://zeevector.com/wp-content/uploads/npci-logo-PNG.png" />
                            <img style={{ marginRight: "0px", width: "80px", height: "48px" }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Bharat_BillPay_logo.svg/1200px-Bharat_BillPay_logo.svg.png" />
                        </div>
                        <div style={{}}>Application Developer</div>
                    </div>

                </div>
                <div style={{ marginBottom: "10px", }}>July 2022 - present</div>
            </div>
            <div style={{ marginTop: "20px", textAlign: "justify" }}>Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description Lorem ipsum description</div>

            {/* Add more experience details as needed */}
        </div>
    </div>);
}