class ThemeConfigg {

    // Static property declaration
    static padding = {
        pageHorizontalPadding: "100px",
        pageHorizontalMobilePadding: "20px",

    };


}
export default ThemeConfigg;