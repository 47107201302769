
import React, { useEffect, useRef, useState } from "react";
import Matter, { Engine, Render, World, Bodies, Mouse, MouseConstraint } from "matter-js";
import Heading from "./Heading";
import ThemeConfigg from "../ThemeConfig";
import { motion } from "framer-motion";
import WebsiteData from "../Data/WebsiteData";

const SkillsSection = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initial check
    const scene = useRef();
    const engine = useRef(Engine.create());

    // const maxRadius = 120; // Maximum radius for the circles

    // Array of skills with their levels
    // const skills = [
    //     { name: "JAVA", level: 100 },
    //     { name: "Spring Boot", level: 90 },
    //     { name: "React", level: 90 },
    //     { name: "Postgres", level: 70 },
    //     { name: "Redis", level: 60 },
    //     { name: "Kafka", level: 60 },
    //     { name: "Cassandra", level: 60 },

    // ];

    const skills = WebsiteData.pfData["skills"];
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const { Render, World, Bodies, Mouse, MouseConstraint } = Matter;
        const width = window.innerWidth - (isMobile ? 40 : 200);
        const height = window.innerHeight - 200;

        const render = Render.create({
            element: scene.current,
            engine: engine.current,
            options: {
                width: width,
                height: height,
                wireframes: false,
                background: "transparent"
            }
        });

        // Define a custom render function for the circles
        const customRender = (body, context) => {
            const { position, circleRadius } = body;
            context.beginPath();
            context.arc(position.x, position.y, circleRadius, 0, 2 * Math.PI);
            context.fillStyle = "rgb(255, 255, 255)";
            // context.strokeStyle = "rgba(255, 255, 255, 0.5)";
            context.lineWidth = 2;
            context.fill();
            context.stroke();
            context.closePath();

            const text = body.label; // Use the label (skill name) as the text
            context.fillStyle = "black";
            context.font = `bold ${circleRadius / 4}px Arial`;
            context.textAlign = "center";
            context.textBaseline = "middle";
            context.fillText(text, position.x, position.y);
        };

        // Create circles based on the skills array
        const circles = skills.map(skill => {
            const radius = (skill.level / 100) * (isMobile ? 70 : 120);
            return Bodies.circle(
                Math.random() * (width - 200),
                Math.random() * height,
                radius,
                {
                    label: skill.name,
                    render: {
                        sprite: {
                            texture: '', // This will be ignored but is needed to prevent Matter.js default rendering
                        }
                    }
                }
            );
        });

        const walls = [
            Bodies.rectangle(width / 2, 0, width, 1, { isStatic: true, render: { strokeStyle: "white" } }),
            Bodies.rectangle(width / 2, height, width, 1, { isStatic: true, render: { strokeStyle: "white" } }),
            Bodies.rectangle(0, height / 2, 1, height, { isStatic: true, render: { strokeStyle: "white" } }),
            Bodies.rectangle(width, height / 2, 1, height, { isStatic: true, render: { strokeStyle: "white" } })
        ];

        World.add(engine.current.world, [...circles, ...walls]);

        const mouse = Mouse.create(render.canvas);
        const mouseConstraint = MouseConstraint.create(engine.current, {
            mouse: mouse,
            constraint: {
                render: {
                    visible: false
                }
            }
        });

        World.add(engine.current.world, mouseConstraint);
        engine.current.world.gravity.y = 0.8;

        Engine.run(engine.current);
        Render.run(render);

        // Custom render each circle on every tick
        Matter.Events.on(render, "afterRender", () => {
            const context = render.context;
            circles.forEach((circle) => {
                customRender(circle, context);
            });
        });

        const handleResize = () => {
            render.bounds.max.x = window.innerWidth - (isMobile ? 40 : 200);
            render.bounds.max.y = window.innerHeight - (isMobile ? 60 : 200);
            render.options.width = render.bounds.max.x;
            render.options.height = render.bounds.max.y;
            Render.stop(render);
            Render.run(render);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
            Render.stop(render);
            World.clear(engine.current.world);
            Engine.clear(engine.current);
            render.canvas.remove();
            render.canvas = null;
            render.context = null;
            render.textures = {};
        };
    }, [isMobile, skills]);

    return (
        <motion.div
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.6, duration: 0.5 }}
            style={{
                padding: "0px 0px 40px",
                display: "flex",
                flexDirection: "column",
                textAlign: "start"
            }}
        >
            <div style={{ padding: `0px ${isMobile ? ThemeConfigg.padding.pageHorizontalMobilePadding : ThemeConfigg.padding.pageHorizontalPadding}`, marginBlock: "30px", display: "flex", justifyContent: "space-between" }}>
                <Heading name="Skills" />
            </div>
            <div ref={scene} style={{ margin: `0px ${isMobile ? ThemeConfigg.padding.pageHorizontalMobilePadding : ThemeConfigg.padding.pageHorizontalPadding}`, width: "100%", height: "100%" }}>
            </div>
        </motion.div>
    );
};

export default SkillsSection;
