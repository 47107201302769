import React, { Component } from 'react';
import './Background.css'; // Assuming you put your CSS code in a file named styles.css

class AnimatedGradientBackground extends Component {
    constructor(props) {
        super(props);
        this.state = {
            animationClass: 'test'
        };
        this.changeState = this.changeState.bind(this);
    }

    changeState() {
        if (this.state.animationClass === 'test') {
            this.setState({
                animationClass: 'test paused'
            });
        } else {
            this.setState({
                animationClass: 'test'
            });
        }
    }

    render() {
        return (
            <div className={this.state.animationClass} >
                {/* <div className="grain-overlay"></div>
                <div className="grain"></div> */}
                < div className="grain animate-grain page-header" ></div >

            </div >
        );
    }
}

export default AnimatedGradientBackground;
