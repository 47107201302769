import React, { useEffect, useState } from 'react';
import { FaLinkedin, FaInstagram, FaGithub, FaHackerrank } from 'react-icons/fa';
import ThemeConfigg from '../ThemeConfig';

function CustomFooter() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Change the threshold according to your design
        };

        handleResize(); // Initial check
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return (
        <div style={{ color: '#fff', padding: `40px ${isMobile ? ThemeConfigg.padding.pageHorizontalMobilePadding : ThemeConfigg.padding.pageHorizontalPadding}`, display: 'flex', justifyContent: 'end' }}>
            <div>© Portfolio X Yash Dhiman </div>
        </div>
    );
}

export default CustomFooter;
