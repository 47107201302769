import { motion } from "framer-motion";
import CustomHeader from "../MainComponent/CustomHeader";
import { useEffect, useState } from "react";
import ThemeConfigg from "../ThemeConfig";
import { Link } from "react-router-dom";
import Heading from "../HomeScreen/Heading";
import CustomFooter from "../MainComponent/Footer";
import WebsiteData from "../Data/WebsiteData";
import "./ProjectsScreen.css";
const projectData = WebsiteData.projectsData;

export default function ProjectsScreen() {
    const [isMobile, setIsMobile] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("All");



    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Change the threshold according to your design
        };

        handleResize(); // Initial check
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const uniqueCategories = ["All", ...new Set(projectData.map((project) => project.projectCategory))];

    const getCategoryCount = (category) => {
        return category === "All"
            ? projectData.length
            : projectData.filter(project => project.projectCategory === category).length;
    };

    const filteredProjects = selectedCategory === "All" ? projectData : projectData.filter(project => project.projectCategory === selectedCategory);

    return (
        <div>
            <CustomHeader />
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                style={{ width: "100vw", color: "white" }}
            >
                <div style={{ height: "120px" }}></div>
                <motion.div
                    initial={{ y: -50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.2, duration: 0.5 }}
                    style={{ fontSize: "40px", margin: `0px ${isMobile ? "20px" : "100px"}`, }}
                >
                    <Heading name="Projects" />
                </motion.div>

                <div style={{ margin: `20px ${isMobile ? "20px" : "100px"}`, marginBottom: "0px", marginTop: "20px" }}>
                    <div style={{ display: "flex" }}>
                        {uniqueCategories.map((category, index) => (
                            <div
                                key={index}
                                style={{
                                    marginRight: "5px",
                                    color: selectedCategory === category ? "white" : "grey",
                                    cursor: "pointer",
                                    fontSize: "13px",
                                    padding: selectedCategory !== category ? "5px 12px" : "5px 12px",
                                    border: selectedCategory === category ? "1px solid white" : "2px solid transparent",
                                    borderRadius: "100px",
                                }}
                                onClick={() => setSelectedCategory(category)}
                            >
                                {category}  {/*({getCategoryCount(category)}) */}
                            </div>
                        ))}
                    </div>
                </div>

                <div style={{ marginTop: "30px" }}>
                    {filteredProjects.map((project, index) => (
                        <ProjectCard key={index} project={project} />
                    ))}
                </div>
                <CustomFooter />
            </motion.div>
        </div>
    );
}


function ProjectCard({ project }) {
    const { projectId, projectName, oneLineDescription, description, techStack, imageUrl } = project;
    const [isMobile, setIsMobile] = useState(false);

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseMove = (e) => {
        const card = e.currentTarget.getBoundingClientRect();
        const x = e.clientX - card.left;
        const y = e.clientY - card.top;
        e.currentTarget.style.setProperty('--x', `${x}px`);
        e.currentTarget.style.setProperty('--y', `${y}px`);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    if (isMobile) {
        return (
            <div>


                <Link to={`/project/${projectId}`} style={{
                    fontSize: "15px",
                    color: "white",
                    textDecoration: "none"
                }}>
                    <motion.div
                        initial={{ opacity: 0 }}
                        transition={{ duration: 1 }}
                        whileInView={{ opacity: 1 }}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        onMouseMove={handleMouseMove}
                        style={{
                            zIndex: 1,
                            marginTop: "20px",
                            backgroundColor: "rgba(255, 255, 255, 0.05)",
                            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                            backdropFilter: "blur(9px)",
                            WebkitBackdropFilter: "blur(9px)",
                            border: "1px solid rgba(255, 255, 255, 0.22)",
                            backdropFilter: "blur(10px) opacity(0.8)",
                            marginLeft: "20px",
                            marginRight: "20px",
                            marginBottom: "30px",
                            display: "flex",
                            flexDirection: "column",
                            borderRadius: isMobile ? "5px" : "10px",
                            overflow: "hidden",
                            ":hover": {
                                backgroundColor: "rgba(255, 255, 255, 0.15)"
                            }

                        }}

                    >
                        <motion.img
                            whileHover={{ scale: 1.02 }}
                            style={{ aspectRatio: "4/3", width: "100%" }}
                            src={imageUrl[0]}
                            alt="project image"
                        />
                        <div style={{ padding: "20px" }}>
                            <div style={{ fontSize: "22px", marginBottom: "0px" }}>{projectName}</div>
                            <div style={{ color: "white", fontSize: "15px", lineHeight: "1.6", textAlign: "justify", marginBottom: "20px" }}>{description}</div>
                            <div style={{ textAlign: "end" }}>
                                <div style={{ fontSize: "15px", marginBottom: "4px" }}>Stack: </div>

                                <div style={{ fontSize: "15px", marginBottom: "10px" }}>{techStack.join(", ")}</div>
                            </div>
                        </div>
                    </motion.div >
                </Link>
            </div>
        );
    } else {

        return (
            <div>

                <Link to={`/project/${projectId}`} style={{
                    fontSize: "15px",
                    color: "white",
                    textDecoration: "none"
                }}>
                    <motion.div

                        // className={isHovered ? "glow" : ""}
                        initial={{ opacity: 0 }}
                        transition={{ duration: 1 }}
                        whileInView={{ opacity: 1 }}
                        style={{
                            zIndex: 1,
                            display: "flex",
                            padding: "30px 30px",
                            backgroundColor: "rgba(255, 255, 255, 0.25)",
                            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                            backdropFilter: "blur(9px)",
                            WebkitBackdropFilter: "blur(9px)",
                            border: "2px solid rgba(255, 255, 255, 0.22)",
                            margin: `0px ${ThemeConfigg.padding.pageHorizontalPadding}`,
                            marginBottom: "50px",
                            borderRadius: "10px",
                            overflow: "hidden",
                            backdropFilter: "blur(10px) opacity(0.8)"
                        }}
                    >
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "50%" }}>
                            <div>
                                <div style={{ fontSize: "30px", fontWeight: "bold", marginBottom: "10px" }}>{projectName}</div>
                                <div style={{ width: "100%", fontSize: "17px", lineHeight: "1.6", textAlign: "justify" }}>
                                    {oneLineDescription}
                                </div>

                            </div>
                            <div style={{ textAlign: "start" }}>
                                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                                    <div style={{ fontSize: "15px", marginRight: "10px", marginBottom: "10px" }}>Tech stack:</div>
                                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "start" }}>
                                        {project.techStack.map((tech) => (
                                            <div key={tech} style={{
                                                height: "25px", marginRight: "10px",
                                                marginBottom: "10px", borderRadius: "50px", alignContent: "center", fontSize: "13px", padding: "0px 15px", border: "0.5px solid white", textOverflow: "ellipsis"
                                            }}>{tech}</div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <motion.div
                            whileHover={{ scale: 1.02 }}
                            style={{ width: "50%", padding: "0px" }}
                        >
                            <img style={{ borderRadius: "4px", width: "100%", borderRadius: "5px" }} src={imageUrl[0]} alt="project image" />
                        </motion.div>
                    </motion.div>
                </Link>
            </div>
        );
    }
}
