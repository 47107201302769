import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import emailjs from 'emailjs-com';
import ThemeConfigg from "../ThemeConfig";
import Heading from "./Heading";
import { CgArrowTopRight } from "react-icons/cg";
import WebsiteData from "../Data/WebsiteData";

export function ContactSection() {
    const [isMobile, setIsMobile] = useState(false);
    const [textareaValue, setTextareaValue] = useState("");
    const contactRef = useRef(null);
    const formRef = useRef(null);

    useEffect(() => {
        if (window.location.hash === "#contact") {
            scrollToContact();
        }
    }, []);

    const scrollToContact = () => {
        contactRef.current.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Change the threshold according to your design
        };

        handleResize(); // Initial check
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();

        emailjs.sendForm(WebsiteData.emailData["serviceId"], WebsiteData.emailData["templateId"], formRef.current, WebsiteData.emailData["userId"])
            .then((result) => {
                console.log(result.text);
                alert('Message sent successfully!');
            }, (error) => {
                console.log(error.text);
                alert('Failed to send the message, please try again later.');
            });
    };

    useEffect(() => {
        const handleInteraction = (event) => {
            if (event.key === 'Tab') {
                setTextareaValue("Great website!, we have an interesting project and would like to have you in! Reach us at our email address:");
            }
        };

        window.addEventListener('mousedown', handleInteraction);
        window.addEventListener('keydown', handleInteraction);

        return () => {
            window.removeEventListener('mousedown', handleInteraction);
            window.removeEventListener('keydown', handleInteraction);
        };
    }, []);

    return (
        <motion.div
            id="contact"
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.8, duration: 0.5 }}
            style={{
                padding: `${isMobile ? "0px" : "40px"} 0px`,
                display: "flex",
                flexDirection: "column",
                textAlign: "start"
            }}
            ref={contactRef}
        >
            <div style={{ marginBottom: "50px", padding: `0px ${isMobile ? ThemeConfigg.padding.pageHorizontalMobilePadding : ThemeConfigg.padding.pageHorizontalPadding}`, marginBlock: "30px", display: "flex", justifyContent: "space-between" }}>
                <Heading name="Let's work together" />
            </div>
            <div style={{ padding: `0px ${isMobile ? ThemeConfigg.padding.pageHorizontalMobilePadding : ThemeConfigg.padding.pageHorizontalPadding}`, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "start", height: "100%" }}>
                <div style={{ fontSize: isMobile ? "30px" : "40px", marginBottom: "30px" }}>Hi Yash,</div>
                <div style={{ marginBottom: "10px", border: "1px solid grey", padding: "5px 10px", fontSize: "13px", color: "grey" }} tabIndex="0">Tab</div>
                <form ref={formRef} onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', width: isMobile ? "70%" : "50%" }}>
                    <textarea
                        name="message"
                        placeholder="Great website!, we have an interesting project and would like to have you in! Reach us at our email address:"
                        rows="5"
                        value={textareaValue}
                        onChange={(e) => setTextareaValue(e.target.value)}
                        style={{ caretColor: "white", outline: "none", padding: "0px", marginBottom: "20px", color: "white", backgroundColor: "transparent", border: "none", fontSize: isMobile ? "15px" : "22px" }}
                        required
                    ></textarea>
                    <button type="submit" style={{ fontSize: `${isMobile ? "13px" : "15px"}`, backgroundColor: "white", color: "black", aspectRatio: `${isMobile ? "2.4" : "3.2"}`, width: `${isMobile ? "100px" : "150px"}`, textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>{isMobile ? "Send" : "Send Message"}</button>
                </form>
                <div style={{ display: "flex", flexDirection: isMobile ? "column" : "row", marginTop: `${isMobile ? "60px" : "20px"}` }}>
                    <div style={{ fontSize: "20px", marginBottom: "20px", marginRight: "40px" }}>Or reach me through here:</div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ marginRight: "20px", display: "flex" }}>
                            <a href={WebsiteData.pfData["links"][0]["value"]} target="_blank" style={{ fontSize: isMobile ? "15px" : "20px", textDecoration: "none", color: "white" }} tabIndex="0">Linkedin</a>
                            <CgArrowTopRight style={{ fontSize: "18px", padding: "0px", marginLeft: "0px", paddingTop: "0px" }} />
                        </div>
                        <div style={{ marginRight: "20px", display: "flex" }}>
                            <a href={WebsiteData.pfData["links"][1]["value"]} target="_blank" style={{ fontSize: isMobile ? "15px" : "20px", textDecoration: "none", color: "white" }} tabIndex="0">Github</a>
                            <CgArrowTopRight style={{ fontSize: "18px", padding: "0px", marginLeft: "0px", paddingTop: "0px" }} />
                        </div>
                        <div style={{ marginRight: "20px", display: "flex" }}>
                            <a href={WebsiteData.pfData["links"][2]["value"]} target="_blank" style={{ fontSize: isMobile ? "15px" : "20px", textDecoration: "none", color: "white" }} tabIndex="0">Email</a>
                            <CgArrowTopRight style={{ fontSize: "18px", padding: "0px", marginLeft: "0px", paddingTop: "0px" }} />
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ height: isMobile ? "40px" : "0px" }}></div>
        </motion.div >
    );
}
