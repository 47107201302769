import { motion } from "framer-motion";
import CustomHeader from "../MainComponent/CustomHeader";
import Footer from "../MainComponent/Footer";
import { useEffect, useState } from "react";
import ThemeConfigg from "../ThemeConfig";
import CoderImage from "./CoderImage";
import { RiTextDirectionL } from "react-icons/ri";
import { LiaLinkedin } from "react-icons/lia";
import LandingMainSection from "./LandingMainSection";
import { AboutSection } from "./AboutSection";
import Heading from "./Heading";
import ExperienceSection from "./ExperienceSection";
import SkillsSection from "./SkillsSection";
import { ContactSection } from "./ContactSection";
import { Link, ScrollRestoration } from "react-router-dom";

import CustomFooter from "../MainComponent/Footer";
import WebsiteData from "../Data/WebsiteData";

import ThemeConfig from "../ThemeConfig";
import "./ProjectSection.css";
export default function ProjectSection() {
    const projects = WebsiteData.projectsData;
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Change the threshold according to your design
        };

        handleResize(); // Initial check
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div style={{ padding: "40px 0px 20px 0px", height: isMobile ? "" : "100vh", display: "flex", flexDirection: "column", textAlign: "start", justifyContent: "start" }}>
            <div style={{ padding: `0px ${isMobile ? ThemeConfig.padding.pageHorizontalMobilePadding : ThemeConfig.padding.pageHorizontalPadding}`, marginBlock: "30px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Heading name="Projects" />
                <Link to="/projects" style={{ fontSize: "14px", marginRight: "10px", color: "white", textDecoration: "none" }}>See All</Link>
            </div>

            <div style={{ overflowX: "auto", overflowY: "hidden", whiteSpace: "nowrap", display: "flex" }}>
                {projects.map((project, index) => (
                    <ProjectCard key={project.projectId} index={index} project={project} isMobile={isMobile} isFirst={index === 0 ? "T" : "F"} lastElement={index === projects.length - 1 ? "T" : "F"} />
                ))}
            </div>
        </div>
    );
}

export function ProjectCard({ project, index, lastElement }) {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Change the threshold according to your design
        };

        handleResize(); // Initial check
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Link to={`/project/${project.projectId}`} style={{
            fontSize: "15px",
            color: "white",
            textDecoration: "none"
        }}>
            <div style={{
                height: "100%",
                marginLeft: index === 0 ? (isMobile ? (ThemeConfigg.padding.pageHorizontalMobilePadding) : ThemeConfigg.padding.pageHorizontalPadding) : ("20px"),
                marginRight: lastElement == "T" ? (isMobile ? "20px" : "100px") : "0px",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column"
            }}>

                <img
                    src={project.imageUrl[0]}
                    alt={`${project.projectName} image`}
                    style={{ aspectRatio: "4/3", width: `${isMobile ? "calc(100vw - 40px)" : "500px"}`, backgroundColor: "grey", borderRadius: "0px" }}
                />

                <div style={{ fontSize: isMobile ? "20px" : "27px", marginTop: "15px" }}>{project.projectName}</div>
                <div style={{ fontSize: isMobile ? "15px" : "18px", marginTop: "5px", color: "grey" }}>{project.oneLineDescription}</div>
            </div>
        </Link>
    );
}


