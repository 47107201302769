import { useEffect, useState, useRef } from "react";
import gsap from "gsap";
import ThemeConfigg from "../ThemeConfig";
import WebsiteData from "../Data/WebsiteData";

const greetings = {
    en: "Hi",
    fr: "Bonjour",
    es: "Hola",
    de: "Hallo",
    it: "Ciao",
    pt: "Olá",
    // Add more languages as needed
};

export default function LandingMainSection() {
    const [isMobile, setIsMobile] = useState(false);
    const [hiIndex, setHiIndex] = useState(0);
    const hiTextRef = useRef(null);
    const introRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Change the threshold according to your design
        };

        handleResize(); // Initial check
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setHiIndex(prevIndex => (prevIndex + 1) % Object.keys(greetings).length);
        }, 2000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        gsap.fromTo(hiTextRef.current, { opacity: 0 }, { opacity: 1, duration: 1, delay: 0.5 });
        gsap.fromTo(introRef.current, { opacity: 0, x: -100 }, { opacity: 1, x: 0, duration: 1, delay: 1 });
    }, []);

    const language = Object.keys(greetings)[hiIndex];
    const hiText = greetings[language];

    return (
        <div
            style={{ padding: `${isMobile ? "70px" : "80px"} ${isMobile ? "20px" : ThemeConfigg.padding.pageHorizontalPadding} 80px ${isMobile ? "20px" : ThemeConfigg.padding.pageHorizontalPadding}`, height: "calc(100vh)", display: "flex", flexDirection: "column", justifyContent: "center", color: "white", textAlign: "center", alignContent: "center", alignItems: "center" }}
        >
            <div ref={hiTextRef}>
                <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center" }}>
                    <div style={{ width: isMobile ? "100%" : "50%" }}>
                        <div style={{ textAlign: "center", fontSize: isMobile ? "50px" : "80px", lineHeight: "1", fontWeight: "bold", marginBottom: isMobile ? "20px" : "30px", marginRight: isMobile ? "" : "200px" }}>
                            {hiText}
                        </div>
                    </div>
                </div>
            </div>
            <div ref={introRef} style={{ marginBottom: "5px", textAlign: "center", width: isMobile ? "100%" : "50%" }}>
                <div style={{ lineHeight: "1.5", textAlign: "center", marginBottom: "0px", fontSize: isMobile ? "27px" : "35px", fontWeight: "normal" }}>I'm Yash Dhiman. a Full Stack developer based in Mumbai, India.</div>
            </div>
        </div>
    );
}
