class WebsiteData {

    static emailData = {
        "serviceId": "service_222frr8",
        "templateId": "template_vfwklvn",
        "userId": "phxOsffwNm7qr4aYp",
        "mailTo": "2706yash@gmail.com"
    };

    static pfData = {
        "name": "Yash Dhiman",
        "introLine": "My name is Yash Dhiman.\nI'm A Full stack developer, based in Mumbai, India.",
        "about": "I'm dedicated to crafting visually captivating software interfaces that resonate deeply with users, leaving a lasting impression.",
        "resumeLink": "https://drive.google.com/file/d/1uDqrlB4HT6vmcGffG-mCKz0h0Bn_dcQ4/view?usp=share_link",
        "skills": [
            { "name": "JAVA", "level": 100 },
            { "name": "Spring Boot", "level": 90 },
            { "name": "React", "level": 90 },
            { "name": "Postgres", "level": 70 },
            { "name": "Redis", "level": 60 },
            { "name": "Kafka", "level": 60 },
            { "name": "Cassandra", "level": 60 },
        ],
        "experience": [
            {
                "name": "NPCI Bharat Billpay Ltd.",
                "duration": "Jul 2022 - present",
                "position": "Application Developer"
            },
            {
                "name": "Hybrid Idea Pvt. Ltd.",
                "duration": "Jul 2020 - Jan 2022",
                "position": "Full Stack Developer"
            },
            {
                "name": "Freelancer",
                "duration": "Jul 2020 - Jun 2022",
                "position": "Full Stack Developer"
            }
        ],
        "links": [
            {
                "value": "https://www.linkedin.com/in/yashdhiman27/",
                "name": "LinkedIn"
            },
            {
                "value": "https://github.com/yashdhiman1",
                "name": "Github"
            },
            {
                "value": "mailto:2706yash@gmail.com",
                "name": "Mail"
            }
        ]
    };
    static projectsData = [
        {
            projectId: "PRJ1",
            projectName: "Makellos",
            oneLineDescription: "Laundry Application",
            projectCategory: "Application",
            description: "description",
            imageUrl: ["https://img.freepik.com/free-vector/furniture-shopping-app-interface_23-2148660330.jpg?t=st=1716963403~exp=1716967003~hmac=2a6f16d92de7b6771afec9766caff38b93204bd12e636aeda91f9b641e01ae93&w=1380", "https://img.freepik.com/free-vector/furniture-shopping-app-interface_23-2148660330.jpg?t=st=1716963403~exp=1716967003~hmac=2a6f16d92de7b6771afec9766caff38b93204bd12e636aeda91f9b641e01ae93&w=1380"]
            , techStack: ["JAVA", "Flutter", "PostgreSQL", "Google Cloud", "Firebase"],
            links: [
                {
                    name: "Play Store",
                    url: "https://play.google.com/store/apps/details?id=com.makellos.makellosapp&hl=en_US&pli=1"
                },
                {
                    name: "App Store",
                    url: "https://apps.apple.com/in/app/makellos/id6471285150"
                }
            ],
        },
        {
            projectId: "PRJ2",
            projectName: "Frodo",
            oneLineDescription: "To-Do App",
            projectCategory: "Application",
            description: "description",
            imageUrl: ["https://miro.medium.com/v2/resize:fit:2000/1*1KeRb1ytlSKhoGehUsl4RQ.png", "https://miro.medium.com/v2/resize:fit:2000/1*1KeRb1ytlSKhoGehUsl4RQ.png"]
            , techStack: ["JAVA", "Flutter", "PostgreSQL", "Google Cloud", "Firebase"],
            links: [
                {
                    name: "Play Store",
                    url: "https://play.google.com/store/apps/details?id=com.makellos.makellosapp&hl=en_US&pli=1"
                },
                {
                    name: "App Store",
                    url: "https://apps.apple.com/in/app/makellos/id6471285150"
                }
            ],
        },
        {
            projectId: "PRJ3",
            projectName: "Foodish",
            oneLineDescription: "online food ordering website",
            projectCategory: "Website",
            description: "description",
            imageUrl: ["https://www.ideamotive.co/hs-fs/hubfs/21%20Dazzling%20Examples%20of%20Mobile%20App%20UI%20Design%20to%20Inspire%20You%20in%202022%20-%20music%20player.png?width=1600&height=1200&name=21%20Dazzling%20Examples%20of%20Mobile%20App%20UI%20Design%20to%20Inspire%20You%20in%202022%20-%20music%20player.png", "https://img.freepik.com/free-vector/furniture-shopping-app-interface_23-2148660330.jpg?t=st=1716963403~exp=1716967003~hmac=2a6f16d92de7b6771afec9766caff38b93204bd12e636aeda91f9b641e01ae93&w=1380"]
            , techStack: ["JAVA", "Flutter", "PostgreSQL", "Google Cloud", "Firebase"],
            links: [
                {
                    name: "Play Store",
                    url: "https://play.google.com/store/apps/details?id=com.makellos.makellosapp&hl=en_US&pli=1"
                },
                {
                    name: "App Store",
                    url: "https://apps.apple.com/in/app/makellos/id6471285150"
                }
            ],
        },
        {
            projectId: "PRJ4",
            projectName: "Project 4",
            projectCategory: "Backend",
            oneLineDescription: "one liner description",
            description: "description",
            imageUrl: ["https://www.ideamotive.co/hs-fs/hubfs/21%20Dazzling%20Examples%20of%20Mobile%20App%20UI%20Design%20to%20Inspire%20You%20in%202022%20-%20music%20player.png?width=1600&height=1200&name=21%20Dazzling%20Examples%20of%20Mobile%20App%20UI%20Design%20to%20Inspire%20You%20in%202022%20-%20music%20player.png", "https://img.freepik.com/free-vector/furniture-shopping-app-interface_23-2148660330.jpg?t=st=1716963403~exp=1716967003~hmac=2a6f16d92de7b6771afec9766caff38b93204bd12e636aeda91f9b641e01ae93&w=1380"]
            , techStack: ["JAVA", "Flutter", "PostgreSQL", "Google Cloud", "Firebase"],
            links: [
                {
                    name: "Play Store",
                    url: "https://play.google.com/store/apps/details?id=com.makellos.makellosapp&hl=en_US&pli=1"
                },
                {
                    name: "App Store",
                    url: "https://apps.apple.com/in/app/makellos/id6471285150"
                }
            ],
        },
    ];



}
export default WebsiteData;