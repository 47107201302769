import logo from './logo.svg';
import './App.css';
import HomeScreen from './HomeScreen/HomeScreen';
import { Route, BrowserRouter as Router, Routes, ScrollRestoration } from 'react-router-dom';
import ProjectsScreen from './ProjectsScreen/ProjectsScreen';
import BlogsScreen from './BlogsScreen/BlogsScreen';
import ResumeScreen from './ResumeScreen/ResumeScreen';
import CircularCursor from './MainComponent/CircularCursor';
import Footer from './MainComponent/Footer';
import CustomHeader from './MainComponent/CustomHeader';
import MovingCircles from './Sample/MovingCircles';
import MeshBackground from './MainComponent/GradBg';
import { Canvas } from 'react-three-fiber';
import AnimatedGradientBackground from './Background/Background';
import LandingMainSection from './HomeScreen/LandingMainSection';
import ProjectScreen from './ProjectScreen/ProjectScreen';
import ScrollToTopOnMount from './ScrollToTopOnMount';
import SplashScreen from './Screens/SplashScreen';
import Dijkstra from './Screens/Djikstra';
import StarTrail from './MainComponent/StarTrail';

function App() {

  return (

    <div>
      {/* <MovingCircles /> */}
      {/* <div style={{ position: "relative", width: '100vw', height: '100vh', background: 'black' }}>
        <Canvas>
          <ambientLight />
          <pointLight position={[10, 10, 10]} />
          <MeshBackground />
        </Canvas>
      </div> */}

      <AnimatedGradientBackground />
      {/* <StarTrail /> */}
      <Router>
        <ScrollToTopOnMount />

        <Routes>
          <Route path="/" element={<SplashScreen />} />
          <Route path="/home" element={<HomeScreen />} />
          <Route path="/projects" element={<ProjectsScreen />} />
          <Route path="/blogs" element={<BlogsScreen />} />
          <Route path="/resume" element={<ResumeScreen />} />
          <Route path="/dijkstra" element={<Dijkstra />} />
          <Route path="/project/:projectId" element={<ProjectScreen />} />

        </Routes>

      </Router>

      {/* <Footer></Footer> */}
      {/* <CircularCursor /> */}
    </div>
  );
}

export default App;
