import { MdOutlineCreate } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom"; // Import useParams
import CustomFooter from "../MainComponent/Footer";
import CustomHeader from "../MainComponent/CustomHeader";
import { useEffect, useState } from "react";
import WebsiteData from "../Data/WebsiteData";

export default function ProjectScreen() {
    const { projectId } = useParams(); // Get the projectId from the URL params
    const navigate = useNavigate();
    const [project, setProject] = useState(null); // State to store the project data


    const projectData = WebsiteData.projectsData;


    useEffect(() => {
        // Find the project with the matching projectId
        const selectedProject = projectData.find(item => item.projectId === projectId);
        setProject(selectedProject); // Set the project data
    }, [projectId]); // Trigger effect when projectId changes

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Change the threshold according to your design
        };

        handleResize(); // Initial check
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Render loading indicator while project data is being fetched
    if (!project) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <CustomHeader />
            <div style={{ display: "flex", flexDirection: "column", height: "100vh", padding: `${isMobile ? "100px 20px 30px 20px" : "100px"}`, paddingTop: "100px", color: "white" }}>
                <Link onClick={(e) => {
                    navigate(-1);
                }} style={{ fontSize: `${isMobile ? "15px" : "25px"}`, color: "grey", textDecoration: "none" }}>Project /</Link>
                <div style={{ fontSize: `${isMobile ? "30px" : "80px"}`, marginBottom: `${isMobile ? "30px" : "60px"}`, marginTop: isMobile ? "10px" : "20px" }}>{project.projectName}</div>
                <div style={{ flex: "1", display: "flex", justifyContent: "space-between" }}>
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <div>
                            <div style={{ fontSize: `${isMobile ? "16px" : "20px"}`, marginBottom: "10px" }}>OVERVIEW</div>
                            <div style={{ textAlign: "justify", lineHeight: "2", fontSize: `${isMobile ? "13px" : "17px"}`, width: `${isMobile ? "100%" : "80%"}` }}>{project.oneLineDescription}</div>
                        </div>
                        <div style={{}}>
                            <div style={{ fontSize: "20px", marginBottom: "20px", marginTop: "20px" }}>TECH STACK</div>
                            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "start" }}>
                                {project.techStack.map((tech) => (
                                    <div key={tech} style={{
                                        height: "30px", marginRight: "10px",
                                        marginBottom: "10px", borderRadius: "50px", fontSize: "14px", padding: "5px 20px", border: "0.5px solid white", textOverflow: "ellipsis"
                                    }}>{tech}</div>
                                ))}
                            </div>
                            <div style={{ textAlign: "end", marginTop: isMobile ? "20px" : "20px" }}>
                                <div style={{}}>
                                    <div style={{ fontSize: "20px", marginBottom: isMobile ? "5px" : "20px" }}>LINKS</div>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
                                        {project.links.map((link, index) => (
                                            <div key={link.name} style={{ marginRight: index === project.links.length - 1 ? "" : "20px" }}>
                                                <Link to={link.url} style={{ fontSize: "14px", color: "white", textDecoration: "none" }}>{link.name}</Link>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: "black", flex: "1" }}></div>
            <div style={{ padding: `${isMobile ? "20px" : "100px"}`, color: "white" }}>
                <div style={{ fontSize: `${isMobile ? "30px" : "70px"}`, marginBottom: "30px" }}>About</div>
                <div style={{ textAlign: "justify", fontSize: `${isMobile ? "15px" : "20px"}`, lineHeight: "2", marginBottom: "100px" }}>
                    {project.description}
                </div>
                {project.imageUrl.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        style={{
                            width: `calc(100vw - ${isMobile ? "40px" : "200px"})`,
                            backgroundColor: "grey",
                            aspectRatio: "4/3",
                            marginBottom: "20px"
                        }}
                        alt={`Project image ${index + 1}`}
                    />
                ))}
            </div>
            <CustomFooter />
        </div>
    );
}
