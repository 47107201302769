import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

export default function SplashScreen() {
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false); // Start exit animation
            setTimeout(() => {
                navigate('/home'); // Navigate after exit animation
            }, 1000); // Duration of exit animation
        }, 2000);

        return () => clearTimeout(timer); // Cleanup the timer if the component is unmounted
    }, [navigate]);

    return (
        <div style={{ backgroundColor: "black", height: "100vh", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
            <AnimatePresence>
                {isVisible && (
                    <motion.div
                        initial={{ opacity: 0, y: 0 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 0 }}
                        transition={{ duration: 1 }}
                        style={{ fontFamily: "RantaiSignature", fontSize: "50px", color: "white" }}
                    >
                        yash . d
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}
