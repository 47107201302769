import React, { useState } from 'react';


const ROWS = 5;
const COLS = 5;

// Function to calculate shortest path using Dijkstra's algorithm
function dijkstra(graph, start, end) {
    const distances = {};
    const visited = {};
    const previous = {};
    const nodes = [];

    for (let vertex in graph) {
        if (vertex === start) {
            distances[vertex] = 0;
        } else {
            distances[vertex] = Infinity;
        }

        nodes.push(vertex);
        previous[vertex] = null;
    }

    while (nodes.length) {
        let smallest = nodes[0];
        nodes.forEach(node => {
            if (distances[node] < distances[smallest]) {
                smallest = node;
            }
        });

        if (smallest === end) {
            let path = [];
            while (previous[smallest]) {
                path.push(smallest);
                smallest = previous[smallest];
            }
            return path.concat(start).reverse();
        }

        if (!smallest || distances[smallest] === Infinity) {
            break;
        }

        nodes.splice(nodes.indexOf(smallest), 1);
        visited[smallest] = true;

        for (let neighbor in graph[smallest]) {
            let alt = distances[smallest] + graph[smallest][neighbor];
            if (alt < distances[neighbor]) {
                distances[neighbor] = alt;
                previous[neighbor] = smallest;
            }
        }
    }
    return null;
}

// Function to generate a random graph (for demonstration)
function generateGraph() {
    const graph = {};
    for (let i = 0; i < ROWS; i++) {
        for (let j = 0; j < COLS; j++) {
            const vertex = `${i}-${j}`;
            graph[vertex] = {};
            if (i > 0) graph[vertex][`${i - 1}-${j}`] = Math.floor(Math.random() * 10) + 1;
            if (i < ROWS - 1) graph[vertex][`${i + 1}-${j}`] = Math.floor(Math.random() * 10) + 1;
            if (j > 0) graph[vertex][`${i}-${j - 1}`] = Math.floor(Math.random() * 10) + 1;
            if (j < COLS - 1) graph[vertex][`${i}-${j + 1}`] = Math.floor(Math.random() * 10) + 1;
        }
    }
    return graph;
}

export default function Dijkstra() {
    const [startNode, setStartNode] = useState(null);
    const [endNode, setEndNode] = useState(null);
    const [shortestPath, setShortestPath] = useState([]);

    const handleNodeClick = (node) => {
        if (!startNode) {
            setStartNode(node);
            setShortestPath([]);
        } else if (!endNode) {
            setEndNode(node);
            setShortestPath(dijkstra(generateGraph(), startNode, node));
        } else {
            setStartNode(null);
            setEndNode(null);
            setShortestPath([]);
        }
    };

    return (
        <div className="App">
            <div className="grid">
                {Array.from({ length: ROWS }).map((_, i) =>
                    Array.from({ length: COLS }).map((_, j) => {
                        const node = `${i}-${j}`;
                        const isStart = startNode === node;
                        const isEnd = endNode === node;
                        const isShortestPath = shortestPath.includes(node);
                        return (
                            <div
                                key={node}
                                className={`node ${isStart ? "start" : ""} ${isEnd ? "end" : ""} ${isShortestPath ? "shortest-path" : ""}`}
                                onClick={() => handleNodeClick(node)}
                            >
                                {node}
                            </div>
                        );
                    })
                )}
            </div>
        </div>
    );
}


